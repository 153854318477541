<template>
  <div class="night-rent-item mb-1">
    <span class="d-flex ml-1" :class="!billingDetail.showCreate ? 'justify-end' : 'justify-space-between'">
      <v-btn
        v-if="billingDetail.showCreate && bookingTypeId !== 12"
        icon
        small
        :disabled="!checkPerUser"
        @click="checkPerUser ? createBilling(billingDetail): ''"
        class="btn-edit"
      >
        <v-icon large>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="billingDetail.showEdit"
        small
        icon
        :disabled="!checkPerUser"
        class="float-right mr-3 btn-edit"
        @click="checkPerUser ? editBilling(billingDetail) : ''"
      >
        <v-icon large>mdi-pencil-circle</v-icon>
      </v-btn>
    </span>

    <!-- 科目名称 -->
    <div class="night-rent-item__main">
      <span class="fz-10">
        <template>
          {{ `${billingDetail.booking.room.name} ${billingDetail.type.name} ${billingDetail.subject}` }}
          <template v-if="billingDetail.note">
            ({{billingDetail.note}})
          </template>
        </template>
      </span>

      <!-- 単位 -->
      <span class="fz-10"> {{ checkShowTextSP(billingDetail) }} </span>

      <!-- 単価 -->
      <span class="fz-10">
        <span
          v-if="billingDetail.priceWithSalesTax !== null"
          class="justify-end fz-12"
        >
          {{ billingDetail.priceWithSalesTax | toThousands }}
        </span>
        <template v-else>
          {{ checkShowValueSP(billingDetail) | toThousands }}
        </template>
      </span>

      <!-- ポイント -->
      <span>
        <v-btn
          icon
          small
          v-if="(billingDetail.spPoint !== null || billingDetail.fpPoint !== null || billingDetail.tvpPoint !== null)"
          @click="editBillingPoint(billingDetail)"
        >
          <v-icon large>mdi-pencil-circle</v-icon>
        </v-btn>
      </span>

      <!-- 個数 -->
      <span class="fz-10">
        <span
          v-if="billingDetail.price !== null"
          class="justify-center fz-10"
        >
          {{ billingDetail.quantity }}
        </span>
        <template v-else> {{ billingDetail.quantity }} </template>
      </span>

      <!-- 消費税 -->
      <span class="fz-10">
        {{ billingDetail.taxRate | percent }}
      </span>

      <!-- 小計 -->
      <span class="fz-10">
        <span
          v-if="billingDetail.price !== null"
        >
          ¥{{ `${billingDetail.subPrice && formatCurrency(billingDetail.subPrice)} ` }}
        </span>
        <span v-else>{{ billingDetail.subPoint | toThousands }}pt</span>
      </span>

      <!-- 請求書 -->
      <span class="fz-10" v-if="bookingTypeId !== 12">
        <span
          v-if="forInvoice"
          :class="!billingDetail.bookingInvoiceId ? 'text-bill' : ''"
        >
          {{ !billingDetail.bookingInvoiceId ? '未作成' : (billingDetail.bookingInvoiceFinalizedAt ? billingDetail.indexPay : '') }}
        </span>
      </span>

      <!-- 請求書作成 -->
      <span v-if="bookingTypeId !== 12">
        <v-checkbox
          v-if="(!billingDetail.bookingInvoiceId && forInvoice)"
          v-model="checkBillingSelected"
          color="#1867C0"
          class="mt-0 pt-0 ml-5"
          hide-details
          :key="billingDetail.id"
          :value="billingDetail.id"
          :disabled="!checkPerUser"
          @change="checkPerUser ? checkPrice($event, billingDetail.id, billingDetail.subPrice) : ''"
        ></v-checkbox>
      </span>
    </div>

    <!-- EDIT DIALOG POINT -->
    <BaseDialog
      v-model="openDialogEdit"
      :visible="openDialogEdit"
      @close="closeEditPoint"
      :title="titlePointCurrent"
    >
      <template v-slot:content>
        <v-form ref="formPoint" @submit.prevent="submitFormPoint">
          <div class="edit-dialog-content">
            <h6 class="mt-5 fz-10 sp-text-color">{{ $t("common.beforeChange") }}{{ selectedChargeUnit }}</h6>
              <span class="fz-12">{{ pointCurrent | toThousands }}</span>
            <h6 class="mt-5 text--red-dark fz-8">
              変更後{{ selectedChargeUnit }}
            </h6>
            <tvos-int-input
              v-model="numberRangerSelected"
              required
              class="red-select"
            />
            <h6 class="mt-5 fz-10 sp-text-color">
              {{ $t("contractDetail.numberOfReturnPoints") }}
            </h6>
            <span class="fz-12">{{ priceSPOld | toThousands({nan: '_'}) }}</span>
            <h6 class="mt-5 fz-10 sp-text-color">
              追加ポイント数
            </h6>
            <span class="fz-12">{{ priceSPNew | toThousands({nan: '_'}) }}</span>

          </div>
        </v-form>
      </template>
      <template v-slot:footer>
        <div class="text-right mt-3">
          <v-btn large class="btn--red-dark fz-14" @click="closeEditPoint">
            <v-icon class="mr-2">mdi-close</v-icon>
            {{ $t("buttons.close") }}
          </v-btn>
          <v-btn
            large
            class="btn--primary ml-3 fz-14"
            @click="submitFormPoint"
          >
            {{ $t("buttons.save") }}
          </v-btn>
        </div>
      </template>
    </BaseDialog>
    <!-- --- -->

    <!-- ADD DIALOG BILLING-->
    <BaseDialog
      :visible="openDialogAddBilling"
      @close="closeCreateBilling"
      :title="titleCreateBilling"
    >
      <template v-slot:content>
        <v-form ref="formCreateCharge" @submit.prevent="submitCreateBilling">
          <div class="add-dialog-content">
            <h6 class="mt-5 text--red-dark fz-10">
              {{ $t("contractDetail.subItemSelection") }}
            </h6>
            <v-autocomplete
              @change="selectAddItemName($event)"
              :item-disabled="disableNoManualCreateTypes"
              v-model="chargeTypeNameSelected"
              :items="chargeTypeNameList"
              item-text="name"
              item-value="id"
              no-data-text="見つかりませんでした。"
              class="red-select"
              :rules="chargeTypeNameBlank"
            ></v-autocomplete>

            <h6 class="mt-5 text--inactive fz-10">
              {{ $t("common.subjectName") }}
            </h6>
            <v-autocomplete
              v-model="subjectNameSelected"
              :items="subjectNameList"
              item-text="name"
              item-value="id"
              no-data-text="見つかりませんでした。"
              :rules="(chargeTypeNameSelected ? subjectNameBlank : [])"
              :disabled="!chargeTypeNameSelected"
            ></v-autocomplete>

            <h6 class="mt-5" v-if="checkDisableFiled">
              {{ $t("contractDetail.namesOfOtherProductsForSale") }}
            </h6>
            <v-text-field
              v-if="checkDisableFiled"
              v-model="noteCreateBilling"
              dense
              type="text"
            ></v-text-field>

            <h6 class="mt-5">
              {{ $t("common.unitPrice") }}
            </h6>
            <v-text-field
              v-model="priceCreateBilling"
              dense
              type="number"
              :min="0"
              :rules="!priceDisable ? priceCreateBillingBlank: []"
              :disabled="priceDisable"
            ></v-text-field>

            <h6 class="mt-5">
              数量
            </h6>
            <v-autocomplete
              v-model="quantityCreateBilling"
              :items="numberRangerList"
              :rules="quantityCreateBillingBlank"
            ></v-autocomplete>
          </div>
        </v-form>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-space-between mt-10">
          <v-btn large class="btn--red-dark" disabled>
            {{ $t("buttons.delete") }}
          </v-btn>
          <div class="d-flex">
            <v-btn large class="btn--red-dark" @click="closeCreateBilling">
              <v-icon class="mr-2">mdi-close</v-icon>
              {{ $t("buttons.close") }}
            </v-btn>
            <v-btn
              large
              class="btn--primary ml-3"
              @click="submitCreateBilling"
            >
              {{ $t("buttons.save") }}
            </v-btn>
          </div>
        </div>
      </template>
    </BaseDialog>
    <!-- --- -->

    <!-- EDIT DIALOG BILLING-->
    <BaseDialog
      :visible="openDialogEditBilling"
      @close="closeEditBilling"
      :title="titleEditBilling"
    >
      <template v-slot:content>
        <v-form ref="formEditCharge" @submit.prevent="submitEditBilling">
          <div class="add-dialog-content">
            <h5 class="mt-5 text--red-dark">
              {{ $t("contractDetail.subItemSelection") }}
            </h5>
            <v-autocomplete
              @change="selectItemName($event)"
              :item-disabled="disableNoManualCreateTypes"
              :disabled="chargeTypeNameSelectedEdit === 7"
              v-model="chargeTypeNameSelectedEdit"
              :items="chargeTypeNameListEdit"
              item-text="name"
              item-value="id"
              no-data-text="見つかりませんでした。"
              class="red-select"
              :rules="chargeTypeNameBlank"
            ></v-autocomplete>

            <h5 class="mt-5 text--inactive">
              {{ $t("common.subjectName") }}
            </h5>
            <v-autocomplete
              v-model="subjectNameSelectedEdit"
              :items="subjectNameListEdit"
              item-text="name"
              item-value="id"
              no-data-text="見つかりませんでした。"
              :rules="(chargeTypeNameSelectedEdit ? subjectNameBlank : [])"
              :disabled="!chargeTypeNameSelectedEdit"
            ></v-autocomplete>

            <h6 class="mt-5" v-if="checkDisable">
              {{ $t("contractDetail.namesOfOtherProductsForSale") }}
            </h6>
            <v-text-field
              v-if="checkDisable"
              v-model="noteCreateBillingEdit"
              dense
              type="text"
            ></v-text-field>

            <h5 class="mt-5 text--inactive">
              {{ $t("common.unitPrice") }}
            </h5>
            <v-text-field
              v-model="priceCreateBillingEdit"
              dense
              type="number"
              :min="0"
              :rules="!priceDisableEdit ? priceCreateBillingBlank: []"
              :disabled="priceDisableEdit"
            ></v-text-field>

            <h5 class="mt-5 text--inactive">
              数量
            </h5>
            <v-autocomplete
              v-model="quantityCreateBillingEdit"
              :items="numberRangerList"
              :rules="quantityCreateBillingBlank"
            ></v-autocomplete>
          </div>
        </v-form>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-space-between mt-10">
          <v-btn large class="btn--red-dark" @click="removeBilling(billingDetail)">
            {{ $t("buttons.delete") }}
          </v-btn>
          <div class="d-flex">
            <v-btn large class="btn--red-dark" @click="closeEditBilling">
              <v-icon class="mr-2">mdi-close</v-icon>
              {{ $t("buttons.close") }}
            </v-btn>
            <v-btn
              large
              class="btn--primary ml-3"
              @click="submitEditBilling"
            >
              {{ $t("buttons.save") }}
            </v-btn>
          </div>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import moment from 'moment'
import BaseDialog from '@/components/Dialog/BaseDialog'
import { formatCurrency, rangeNumber, handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import {
  UPDATE_POINT_ADDDITIONAL_CHARGE,
  BOOKING_ADDITIONAL_CHARGE_TYPE_LIST,
  BOOKING_ADDITIONAL_CHARGE_SUBJECT_LIST,
  CHARGE_DEFAULT, CREATE_ADDDITIONAL_CHARGE,
  UPDATE_ADDDITIONAL_CHARGE,
  REMOVE_CHARGE
} from '@/api/graphql/billingStatement/listBillingStatement'
import { mapMutations, mapGetters } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { getPriceUnit } from '@/utils/billing'
import gql from 'graphql-tag'

export default {
  name: 'NightRentItem',
  data () {
    return {
      checkDisableFiled: false,
      checkDisable: false,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      checkBillingSelected: null,
      openDialogAdd: false,

      // edit point
      titlePointCurrent: '',
      openDialogEdit: false,
      numberRangerList: rangeNumber(1, 101, 1),
      numberRangerSelected: null,
      priceSPOld: '_',
      priceSPNew: '_',
      pointCurrent: 0,
      numberPointBlank (v) {
        return v !== null || '変更前SPを入力してください'
      },

      // create billing
      titleCreateBilling: '',
      openDialogAddBilling: false,
      chargeTypeNameSelected: null,
      chargeTypeNameList: [],
      subjectNameList: [],
      subjectNameSelected: null,
      priceCreateBilling: null,
      dataChargeDefaultPrice: null,
      noteCreateBilling: '',
      quantityCreateBilling: null,
      priceDisable: true,

      // edit billing
      openDialogEditBilling: false,
      titleEditBilling: '',
      chargeTypeNameSelectedEdit: null,
      chargeTypeNameListEdit: [],
      subjectNameListEdit: [],
      subjectNameSelectedEdit: null,
      priceCreateBillingEdit: null,
      dataChargeDefaultPriceEdit: null,
      noteCreateBillingEdit: '',
      quantityCreateBillingEdit: null,
      priceDisableEdit: false,

      // rules
      chargeTypeNameBlank: [
        v => v !== null || '小項目選択を入力してください'
      ],
      subjectNameBlank: [
        v => v !== null || '科目名称を入力してください'
      ],
      priceCreateBillingBlank: [
        v => (v !== null && v !== '') || '単価を入力してください'
      ],
      noteCreateBillingBlank: [
        v => !!v || '単価を入力してください'
      ],
      quantityCreateBillingBlank: [
        v => !!v || '数量を入力してください'
      ],
      selectedBilling: null
    }
  },
  props: {
    billingDetail: {
      type: Object,
      required: true,
      default: () => {}
    },
    nightPoint: {
      type: Number,
      required: true,
      default: 1
    },
    bookingTypeId: {
      type: Number,
      required: true,
      default: 1
    }
  },
  components: {
    BaseDialog
  },
  computed: {
    ...mapGetters(['getChargeIds']),
    forInvoice () {
      return this.billingDetail.price !== null && !this.billingDetail.externalPaymentMethodId
    },
    selectedChargeUnit () {
      if (this.selectedBilling) {
        return this.checkShowTextSP(this.selectedBilling)
      }
      return ''
    },
    bookingId () {
      return Number(this.$route.query.id)
    }
  },
  watch: {
    // edit point
    numberRangerSelected (newVal) {
      if (newVal) {
        if (parseInt(newVal) === parseInt(this.pointCurrent)) {
          this.priceSPOld = 0
          this.priceSPNew = 0
        } else {
          const subPoint = newVal - this.pointCurrent
          if (subPoint > 0) {
            this.priceSPNew = subPoint
            this.priceSPOld = '_'
          } else {
            this.priceSPOld = subPoint
            this.priceSPNew = '_'
          }
        }
      }
    },
    // create billing
    chargeTypeNameSelected (newVal) {
      if (newVal) {
        this.subjectNameSelected = null
        this.getSubjectNameList(newVal)
      }
    },
    subjectNameSelected (newVal) {
      if (newVal) {
        this.priceCreateBilling = null
        this.getChargeDefault(newVal)
      }
    },

    // edit Billing
    chargeTypeNameSelectedEdit (newVal) {
      if (newVal) {
        this.subjectNameSelectedEdit = null
        this.getSubjectNameList(newVal, true)
      }
    },
    subjectNameSelectedEdit (newVal) {
      if (newVal) {
        this.getChargeDefault(newVal, true)
      }
    },
    getChargeIds (newVal) {
      if (newVal.length === 0) {
        this.checkBillingSelected = null
      }
    }
  },
  mounted () {
    this.checkBillingSelected = this.forInvoice ? this.billingDetail.id : null
    if (this.bookingTypeId !== 12 && !this.billingDetail.bookingInvoiceId && this.forInvoice) {
      this.checkPrice(this.billingDetail.id, this.billingDetail.id, this.billingDetail.subPrice)
    }
  },

  methods: {
    formatCurrency,
    rangeNumber,
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations(['setCopyErrorText', 'setAlertSuccess', 'setErrorDialog', 'setAlertError', 'setChargeIds', 'removeChargeIds', 'setLoadingOverlayShow', 'setLoadingOverlayHide', 'setPermissionUser', 'setRoleAdminUser']),
    formatDateStay (value) {
      return moment(value).format('yyyy/MM/DD')
    },

    selectAddItemName (event) {
      const arrDisable = [8, 9, 10]
      const item = arrDisable.find((item) => item === event)
      if (item) {
        this.checkDisableFiled = true
        this.noteCreateBilling = ''
      } else this.checkDisableFiled = false
    },

    selectItemName (event) {
      const arrDisable = [8, 9, 10]
      const item = arrDisable.find((item) => item === event)
      if (item) {
        this.checkDisable = true
        this.noteCreateBillingEdit = ''
      } else this.checkDisable = false
    },

    disableNoManualCreateTypes (chargeType) {
      return chargeType.id === 19
    },

    checkShowTextSP (billingDetailText) {
      if (billingDetailText) {
        return getPriceUnit(billingDetailText)
      }
      return ''
    },
    checkShowValueSP (billingDetailValueSP) {
      if (billingDetailValueSP) {
        if (billingDetailValueSP.spPoint !== null) {
          return `${billingDetailValueSP.spPoint}`
        }
        if (billingDetailValueSP.fpPoint !== null) {
          return `${billingDetailValueSP.fpPoint}`
        }
        if (billingDetailValueSP.tvpPoint !== null) {
          return `${billingDetailValueSP.tvpPoint}`
        }
      }
      return ''
    },
    async bookingAdditionalChargeTypeList (editBilling = false) {
      try {
        const dataBookingAdditionalChargeTypeList = await this.$apollo.query({
          query: gql`${BOOKING_ADDITIONAL_CHARGE_TYPE_LIST}`
        })
        if (dataBookingAdditionalChargeTypeList.data.bookingAdditionalChargeTypeList && dataBookingAdditionalChargeTypeList.data.bookingAdditionalChargeTypeList.length > 0) {
          if (editBilling) {
            this.chargeTypeNameListEdit = dataBookingAdditionalChargeTypeList.data.bookingAdditionalChargeTypeList
          } else {
            this.chargeTypeNameList = dataBookingAdditionalChargeTypeList.data.bookingAdditionalChargeTypeList
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getSubjectNameList (typeId, editBilling = false) {
      try {
        const dataSubjectNameList = await this.$apollo.query({
          query: gql`${BOOKING_ADDITIONAL_CHARGE_SUBJECT_LIST}`,
          variables: {
            typeId: parseInt(typeId)
          }
        })
        if (dataSubjectNameList.data.bookingAdditionalChargeSubjectList && dataSubjectNameList.data.bookingAdditionalChargeSubjectList.length > 0) {
          if (editBilling) {
            this.subjectNameListEdit = dataSubjectNameList.data.bookingAdditionalChargeSubjectList
          } else {
            this.subjectNameList = dataSubjectNameList.data.bookingAdditionalChargeSubjectList
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getChargeDefault (subjectId, editBilling = false) {
      try {
        this.priceDisableEdit = false
        const dataChargeDefault = await this.$apollo.query({
          query: gql`${CHARGE_DEFAULT}`,
          variables: {
            subjectId: parseInt(subjectId),
            stayDate: this.billingDetail.stayDate
          }
        })
        if (dataChargeDefault.data.chargeDefault) {
          const dataTmp = dataChargeDefault.data.chargeDefault
          if (dataTmp.defaultPrice !== null) {
            if (editBilling) {
              this.priceCreateBillingEdit = dataTmp.defaultPrice
            } else {
              this.priceCreateBilling = dataTmp.defaultPrice
            }
          }
          this.dataChargeDefaultPrice = dataChargeDefault.data.chargeDefault
          this.dataChargeDefaultPriceEdit = dataChargeDefault.data.chargeDefault
          if (dataTmp && dataTmp.priceChangeAllowed) {
            this.priceDisable = false
          }
          if (editBilling && dataTmp && !dataTmp.priceChangeAllowed) {
            this.priceDisableEdit = true
          }
        }
      } catch (error) {
        console.error(error)
      }
    },

    // begin create billing
    async createBilling (billing) {
      this.openDialogAddBilling = true
      this.titleCreateBilling = `${this.formatDateStay(billing.stayDate)} 追加編集`
      this.bookingAdditionalChargeTypeList()
      this.$nextTick(() => {
        this.$refs.formCreateCharge.resetValidation()
      })
    },
    closeCreateBilling (billing) {
      this.$refs.formCreateCharge.resetValidation()
      this.chargeTypeNameSelected = null
      this.chargeTypeNameList = []
      this.subjectNameList = []
      this.subjectNameSelected = null
      this.priceCreateBilling = null
      this.dataChargeDefaultPrice = null
      this.noteCreateBilling = ''
      this.quantityCreateBilling = null
      this.openDialogAddBilling = false
    },
    async submitCreateBilling () {
      if (this.$refs.formCreateCharge.validate()) {
        this.setLoadingOverlayShow()
        const variables = {
          bookingId: this.bookingId,
          stayDate: this.billingDetail.stayDate,
          subjectId: parseInt(this.subjectNameSelected),
          price: parseInt(this.priceCreateBilling),
          note: this.noteCreateBilling,
          quantity: this.quantityCreateBilling
        }
        await this.$apollo.mutate({
          mutation: gql`${CREATE_ADDDITIONAL_CHARGE}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        }).then((data) => {
          this.setLoadingOverlayHide()
          if (data.data.createAdditionalCharge.id) {
            this.closeCreateBilling()
            this.setAlertSuccess(this.$t('messages.successfulNewCreation'))
            this.$emit('fetchList')
          }
        }).catch(async (error) => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(CREATE_ADDDITIONAL_CHARGE, variables, error.graphQLErrors))
          const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          } else {
            this.setAlertError(this.$t('messages.createError'))
          }
        })
      }
    },
    // end create billing

    // begin edit billing price
    async getSubjectEdit (typeId, nameSubject) {
      try {
        const dataSubjectNameList = await this.$apollo.query({
          query: gql`${BOOKING_ADDITIONAL_CHARGE_SUBJECT_LIST}`,
          variables: {
            typeId: parseInt(typeId)
          }
        })
        if (dataSubjectNameList.data.bookingAdditionalChargeSubjectList && dataSubjectNameList.data.bookingAdditionalChargeSubjectList.length > 0) {
          const filterSubject = dataSubjectNameList.data.bookingAdditionalChargeSubjectList.filter((item) => {
            return item.name === nameSubject
          })
          if (filterSubject) {
            this.subjectNameSelectedEdit = filterSubject[0].id
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async editBilling (billing) {
      this.openDialogEditBilling = true
      this.titleEditBilling = `${this.formatDateStay(billing.stayDate)} 追加編集`
      await this.bookingAdditionalChargeTypeList(true)
      this.chargeTypeNameSelectedEdit = billing.typeId
      this.selectItemName(this.chargeTypeNameSelectedEdit)
      this.quantityCreateBillingEdit = billing.quantity
      this.noteCreateBillingEdit = billing.note
      this.priceCreateBillingEdit = billing.price
      await this.getSubjectEdit(billing.typeId, billing.subject)
      this.$nextTick(() => {
        this.$refs.formEditCharge.resetValidation()
        this.priceCreateBillingEdit = billing.price
      })
    },
    closeEditBilling () {
      this.$refs.formEditCharge.resetValidation()
      this.chargeTypeNameSelectedEdit = null
      this.chargeTypeNameListEdit = []
      this.subjectNameListEdit = []
      this.subjectNameSelectedEdit = null
      this.priceCreateBillingEdit = null
      this.dataChargeDefaultPriceEdit = null
      this.noteCreateBillingEdit = ''
      this.quantityCreateBillingEdit = null
      this.openDialogEditBilling = false
    },
    async submitEditBilling () {
      if (this.$refs.formEditCharge.validate()) {
        this.setLoadingOverlayShow()
        const variables = {
          chargeId: parseInt(this.billingDetail.id),
          quantity: this.quantityCreateBillingEdit,
          price: parseInt(this.priceCreateBillingEdit),
          subjectId: parseInt(this.subjectNameSelectedEdit),
          note: this.noteCreateBillingEdit
        }
        await this.$apollo.mutate({
          mutation: gql`${UPDATE_ADDDITIONAL_CHARGE}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        }).then((data) => {
          this.setLoadingOverlayHide()
          if (data.data.updateAdditionalCharge.id) {
            this.closeEditBilling()
            this.setAlertSuccess(this.$t('messages.successfulNewCreation'))
            this.$emit('fetchList')
          }
        }).catch(async (error) => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(UPDATE_ADDDITIONAL_CHARGE, variables, error.graphQLErrors))
          const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          } else {
            this.setAlertError(this.$t('messages.updateError'))
          }
        })
      }
    },
    // end edit billing price

    // begin edit billing point
    editBillingPoint (billing) {
      this.titlePointCurrent = `${this.formatDateStay(billing.stayDate)} ${this.nightPoint}泊目 ${billing.booking.room.name} ${billing.type.name} 変更`
      this.pointCurrent = this.checkShowValueSP(billing)
      this.selectedBilling = billing
      this.openDialogEdit = true
      this.$nextTick(() => {
        this.$refs.formPoint.resetValidation()
      })
    },
    closeEditPoint () {
      this.openDialogEdit = false
      this.numberRangerSelected = null
      this.pointCurrent = 0
      this.priceSPOld = '_'
      this.priceSPNew = '_'
      this.$refs.formPoint.resetValidation()
    },
    async submitFormPoint () {
      if (this.$refs.formPoint.validate()) {
        this.setLoadingOverlayShow()
        const variables = {
          chargeId: parseInt(this.billingDetail.id),
          newPoint: this.numberRangerSelected
        }
        await this.$apollo.mutate({
          mutation: gql`${UPDATE_POINT_ADDDITIONAL_CHARGE}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        }).then((data) => {
          this.setLoadingOverlayHide()
          if (data.data.updatePointAdditionalCharge.id) {
            this.closeEditPoint()
            this.setAlertSuccess(this.$t('messages.successfulUpdate'))
            this.$emit('fetchList')
          }
        }).catch(async (error) => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(UPDATE_POINT_ADDDITIONAL_CHARGE, variables, error.graphQLErrors))
          const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          } else {
            this.setAlertError(this.$t('messages.updateError'))
          }
        })
      }
    },

    // remove billing point
    async removeBilling (billing) {
      this.setLoadingOverlayShow()
      const variables = {
        chargeId: parseInt(billing.id)
      }
      await this.$apollo.mutate({
        mutation: gql`${REMOVE_CHARGE}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.setLoadingOverlayHide()
        if (data.data.deleteAdditionalCharge) {
          this.setAlertSuccess(this.$t('messages.deletedSuccessfully'))
          this.$emit('fetchList')
        }
      }).catch(async (error) => {
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(REMOVE_CHARGE, variables, error.graphQLErrors))
        const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        } else {
          this.setAlertError(this.$t('messages.updateError'))
        }
      })
    },
    checkPrice (e, idPrice, price = 0) {
      if (e) {
        this.setChargeIds({ chargeIds: e, price: price })
      } else {
        this.removeChargeIds({ chargeIds: idPrice, price: price })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h5{
  font-size: 10px !important;
}
::v-deep {
  .v-select-custom.v-input {
    max-width: 100%;
  }
  .v-messages__message {
    font-size: 10px !important;
  }
  .red-select .v-input__icon--append .v-icon__svg{
    color: #C92D2D !important;
  }
  .v-icon__svg{
    color: #aaa;
  }
  .v-btn:not(.v-btn--round).v-size--large{
    height: 36px !important;
    min-width: 46px !important;
  }
}
.night-rent-item {
  display: flex;
  align-items: center;
  span {
    flex-shrink: 0;
    &:first-child {
      width: 70px;
    }
  }
  &__main {
    width: calc(100% - 74px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 15px 4px 0;
    span {
      width: 60px;
      text-align: center;
      &:nth-child(1) {
        width: 150px;
        text-align: left;
      }
      &:nth-child(5) {
        width: 40px;
      }
      &:nth-child(3),
      &:last-child {
        text-align: right;
      }
    }
  }
  .btn-edit {
    width: 22px;
    height: 22px;
    i {
      font-size: 30px !important;
    }
  }
}
.text-bill {
  color: #C92D2D;
}
.sp-text-color {
  color: #000000;
}
.fz-10 {
  font-size: 10px !important;
}
.fz-9 {
  font-size: 9px !important;
}
.fz-8 {
  font-size: 8px !important;
}
.fz-11 {
  font-size: 11px !important;
}
.fz-13 {
  font-size: 13px !important;
}
.fz-12 {
  font-size: 12px !important;
}
.fz-14 {
  font-size: 14px !important;
}
::v-deep .v-select__selection--comma {
  font-size: 12px !important;
}
::v-deep{
  .v-input--checkbox{
    .v-icon__svg{
      color: #1867C0 !important;
    }
  }
}
@media (min-width: 600px) {
  .edit-dialog-content {
    width: calc(100% - 240px);
  }
  .add-dialog-content {
    width: calc(100% - 90px);
  }
}
</style>
