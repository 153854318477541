<template>
  <div class="d-flex flex-column booking-and-rooms">
    <div v-for="ind in range" :key="ind"
      :style="commonStyle"
      class="room"
      :class="{overbooked, 'is-selected': isSelected(ind)}"
    >
      <div
        v-if="inventory.bookings[ind]"
        class="booking"
        :style="checkTextColor(ind, inventory.inventoryTypeId)"
        :class="{overbooked: isIndOverbooked(ind), block: isIndBlock(ind)}"
        @click="handleClick(ind)"
      >
        <span :style="isIndOverbooked(ind) ? 'color: #fff' : ''">{{inventory.bookings[ind].room.name}}</span>
      </div>
      <div v-else class="room"
        :class="{'is-valid': isValidCell(ind)}"
        @click="handleClick(ind)"
        style="height: 20px; border-color: #cccccc !important">
        <div v-if="inventoryType.externallyReserved" class="external">
          {{inventoryType.nameAbbr}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inventory: Object,
    mode: String,
    isInventorySelected: Function,
    isInventoryValid: Function
  },
  // created () {
  //   localStorage.removeItem('selectedDays')
  // },
  computed: {
    isForNewBooking () {
      return this.mode === 'booking'
    },
    isForInventoryManage () {
      return this.mode === 'manage'
    },
    length () {
      return Math.max(this.inventory.rooms, this.bookingCount)
    },
    bookingCount () {
      return this.inventory.bookings.length
    },
    overbooked () {
      return this.inventory.rooms < this.bookingCount
    },
    inventoryType () {
      return this.$store.getters.inventoryTypes.find(it => it.id === this.inventory.inventoryTypeId)
    },
    overBookedRoomIds () {
      const roomCount = {}
      this.inventory.bookings.forEach(b => {
        roomCount[b.room.id] = (roomCount[b.room.id] ?? 0) + 1
        return roomCount
      })

      Object.keys(roomCount).filter(rid => roomCount[rid] <= 1)
        .forEach(key => {
          delete roomCount[key]
        })
      return roomCount
    },
    commonStyle () {
      return `backgroundColor: #${this.inventoryType.color || 'FFF'}; color: white`
    },
    range () {
      return [...Array(this.length)].map((_, ind) => ind)
    }
  },
  methods: {
    checkTextColor (ind, inventoryTypeId) {
      if (this.isIndBlock(ind)) return 'color: #fff'
      const idTextBlack = [1, 4, 3, 5, 6]
      if (!idTextBlack.includes(inventoryTypeId)) return 'color: #fff'
      else return 'color: #000'
    },

    handleClick (ind) {
      if (this.canClick(ind)) {
        this.$emit('inventoryClick', {
          ind, inventory: this.inventory
        })
      }
    },
    canClick (ind) {
      if (this.isForNewBooking) {
        return true
      } else if (this.isForInventoryManage) {
        return !this.inventory.bookings[ind]
        // the below should not be clickable. Room overbooking is nothing to do with inventory settings
        //   this.isIndOverbooked(ind)
      }
      return true
    },
    isIndOverbooked (ind) {
      return this.inventory.bookings[ind] &&
        this.overBookedRoomIds[this.inventory.bookings[ind].room.id]
    },
    isIndBlock (ind) {
      return this.inventory.bookings[ind]?.blockRoom ?? false
    },
    isSelected (ind) {
      if (this.isInventorySelected) {
        return this.isInventorySelected({
          ind, inventory: this.inventory
        })
      }
      return false
    },
    isValidCell (ind) {
      if (this.isInventoryValid) {
        return this.isInventoryValid({
          ind, inventory: this.inventory
        })
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.room {
  // padding: 2px;
  height: 20px;
  border-bottom: 1px solid $calendar-border-color;
  &.overbooked > * {
    background-color: red !important;
    &.block {
      background-color: #C00000 !important;
    }
  }
  &.is-selected {
    border: 1px solid red;
  }
  &.is-valid {
    background-color: $calendar-border-color !important;
  }
}
.booking {
  // border: 1px solid white;
  color: white;
  position: relative;
  z-index: 1;
  // border-radius: 2px;
  &.overbooked {
    height: 100%;
    &::after {
      background-color: red !important;
    }
    &.block::after {
      background-color: #C00000 !important;
    }
  }
  // block should have different color. see TO2020-1343
  &.block {
    background-color: black;
    &.overbooked {
      background-color: #C00000;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 2px;
    width: calc(100% - 4px);
    height: 90%;
    border: 1px solid rgba(255,255,255,0.9);
    border-radius: 4px;
    z-index: -1;
  }
}
.booking-and-rooms {
  width: 100%;
  text-align: center;
  &:empty {
    display: none !important;
  }
}
</style>
